import { BaseIcon, SVGProps } from '@aurelius/icons'

export const ContainerIcon = ({ fill = '#000', ...rest }: SVGProps) => (
  <BaseIcon title="Container Icon" viewBox="0 0 50 50" {...rest} fill={fill}>
    <path
      fill="none"
      stroke={fill}
      strokeMiterlimit="10"
      strokeWidth="2"
      d="M5,12v26c0,3.866,3.134,7,7,7h26	c3.866,0,7-3.134,7-7V12c0-3.866-3.134-7-7-7H12C8.134,5,5,8.134,5,12z"
    />
  </BaseIcon>
)
