import {
  Box,
  Button,
  Flex,
  Image,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spinner,
  Text,
} from '@chakra-ui/react'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import ReactPlayer from 'react-player'
import { convertTimeToSeconds, formatTime } from '../Document/MediaProgressBar'
import { ErrorMessage } from '../ErrorMessage'
import { PauseIcon } from '../svg/PauseIcon'
import { PlayIcon } from '../svg/PlayIcon'

const VideoTitle = ({ children }: React.PropsWithChildren) => (
  <Text
    position="absolute"
    top={2}
    left={2}
    zIndex={1} // Positioned at top-left
    color="white"
    fontSize="sm"
    whiteSpace="nowrap"
    overflow="hidden"
    textOverflow="ellipsis" // This creates the "..." cutoff
    maxWidth="calc(100% - 10px)" // Account for padding/margin
    className="titleWrapper"
    opacity={0} // Initially hidden
    transition="opacity 0.2s linear" // Smooth transition
    cursor="text"
    p={2}
    backgroundColor="rgba(72, 72, 72, 0.8)"
    borderRadius="4px"
  >
    {children}
  </Text>
)
interface VideoPlayerProps {
  videoUrl?: string
  thumbnailUrl?: string
  startTime?: string
  endTime?: string
  width?: number
  height?: number
  videoName?: string
  enablePlayback?: boolean
  onClick?: (e: React.MouseEvent) => void
}

export const VideoPlayer: React.FC<VideoPlayerProps> = ({
  videoUrl,
  thumbnailUrl,
  startTime,
  endTime,
  width,
  height,
  videoName,
  enablePlayback = true,
  onClick,
}) => {
  const playerRef = useRef<ReactPlayer>(null)

  const [isLoading, setLoading] = useState<boolean>(true)
  const [isPlaying, setIsPlaying] = useState(false)
  const [played, setPlayed] = useState(0)
  const [showPreview, setShowPreview] = useState(true)
  const [error, setError] = useState<Error | null>(null)

  const clipStartTime = useMemo(() => {
    return startTime ? convertTimeToSeconds(startTime) : 0
  }, [startTime])

  const clipEndTime = useMemo(() => {
    return endTime ? convertTimeToSeconds(endTime) : 0
  }, [endTime])

  const clipDuration = useMemo(() => {
    return endTime && startTime ? clipEndTime - clipStartTime : null
  }, [endTime, startTime, clipEndTime, clipStartTime])

  useEffect(() => {
    if (videoUrl) {
      setLoading(false)
    }
  }, [videoUrl])

  const handleProgress = useCallback(
    (state: { played: number; playedSeconds: number }) => {
      if (clipEndTime > 0) {
        if (state.playedSeconds >= clipEndTime) {
          setIsPlaying(false)
          playerRef.current?.seekTo(clipStartTime, 'seconds')
          setPlayed(0)
        } else if (state.playedSeconds >= clipStartTime) {
          setPlayed(state.playedSeconds - clipStartTime)
        }
      } else {
        setPlayed(state.playedSeconds)
      }
    },
    [clipEndTime, clipStartTime],
  )

  const handlePlayPause = useCallback(() => {
    setIsPlaying((isPlaying) => !isPlaying)

    if (
      !isPlaying &&
      played >= clipEndTime - clipStartTime &&
      clipEndTime - clipStartTime > 0
    ) {
      playerRef.current?.seekTo(clipStartTime, 'seconds')
      setPlayed(0)
    }
  }, [clipEndTime, clipStartTime, isPlaying, played])

  const handleSeek = useCallback(
    (value: number) => {
      const seekValue = Math.min(
        Math.max(0, value),
        clipEndTime - clipStartTime || Infinity, // Handle cases where clipEndTime is not defined
      )
      setPlayed(seekValue)
      playerRef.current?.seekTo(seekValue + clipStartTime, 'seconds')
    },
    [clipEndTime, clipStartTime],
  )

  const handleError = (e: any) => {
    console.error('Video player error', e)
    setError(e as Error)
  }

  const handlePreviewClick = useCallback(
    (e: React.MouseEvent) => {
      if (onClick) {
        onClick(e) // Pass event to parent's handler
      }

      if (!e.defaultPrevented && enablePlayback) {
        if (showPreview) {
          setShowPreview(false)
          setIsPlaying(true)
        }
      }
    },
    [enablePlayback, onClick, showPreview],
  )

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  return (
    <Box
      width={`${width}px`}
      height={`${height}px`}
      _hover={{
        '.controlsWrapper': { opacity: 1 }, // Apply hover effect to controls
        '.titleWrapper': { opacity: 1 }, // Apply hover effect to controls
      }}
      onClick={handlePreviewClick} // Handle preview click, and playback
    >
      {isLoading ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner maxWidth={width} maxHeight={height} h="50px" w="50px" />
        </Box>
      ) : showPreview ? (
        <Flex
          w="100%"
          h="100%"
          justifyContent="center"
          alignItems="center"
          cursor="pointer" // Indicate clickability
          position="relative"
        >
          <Image
            width={width}
            height={height}
            src={thumbnailUrl}
            alt={videoName}
            objectFit="cover"
          />
          <VideoTitle>{videoName}</VideoTitle>
          <PlayIcon
            height={24}
            width={24}
            fill="#ffffff"
            style={{
              position: 'absolute',
            }}
          />
        </Flex>
      ) : (
        <Flex justify="center" align="center">
          <VideoTitle>{videoName}</VideoTitle>
          <ReactPlayer
            ref={playerRef}
            url={videoUrl}
            controls={false}
            width={width}
            height={height}
            onProgress={handleProgress}
            playsinline
            playing={isPlaying}
            onEnded={() => setIsPlaying(false)}
            onError={handleError}
            config={{
              file: {
                forceVideo: true,
              },
            }}
          />
          <Flex
            width="100%"
            height="100%"
            opacity={0} // Initially hidden
            transition="opacity 0.2s linear" // Smooth transition
            className="controlsWrapper"
            position="absolute"
            flexDirection="column"
          >
            <Box flexGrow={1} onClick={handlePlayPause} />
            <Flex
              alignItems="center"
              width="100%"
              p={2}
              backgroundColor="#484848"
              borderRadius={16}
            >
              <Button
                onClick={handlePlayPause}
                mr={2}
                background="transparent"
                padding={0}
                _hover={{ background: 'transparent' }}
              >
                {isPlaying ? (
                  <PauseIcon height={24} width={24} fill="#ffffff" />
                ) : (
                  <PlayIcon height={24} width={24} fill="#ffffff" />
                )}
              </Button>
              <Slider
                aria-label="video-progress"
                min={0}
                max={clipDuration ?? 0} // Ensures slider works correctly if no clipEndTime
                value={played}
                onChange={handleSeek}
                flex="1"
                mr={2}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
              <Text whiteSpace="nowrap" textColor="#ffffff">
                {clipDuration
                  ? `${formatTime(played)} / ${formatTime(clipDuration)}`
                  : formatTime(played)}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Box>
  )
}
