interface DimensionProps {
  height?: number
  width?: number
}

const dimensionProps = (props: DimensionProps) => ({
  height: `${props.height}px`,
  width: `${props.width}px`,
})

export function removeUndefinedProperties<T extends object>(obj: T): T {
  for (const key in obj) {
    if (obj[key] === undefined) {
      delete obj[key]
    }
  }
  return obj
}

interface SpacingProps {
  margin?: number | string
  marginBottom?: number | string
  marginLeft?: number | string
  marginRight?: number | string
  marginTop?: number | string
  padding?: number | string
  paddingBottom?: number | string
  paddingLeft?: number | string
  paddingRight?: number | string
  paddingTop?: number | string
}

const spacingProps = (props: SpacingProps) => ({
  margin: props.margin,
  marginBottom: props.marginBottom,
  marginLeft: props.marginLeft,
  marginRight: props.marginRight,
  marginTop: props.marginTop,
  padding: `${props.padding ?? 0}px`,
  paddingBottom: props.paddingBottom,
  paddingLeft: props.paddingLeft,
  paddingRight: props.paddingRight,
  paddingTop: props.paddingTop,
})

interface BorderProps {
  borderColor?: string
  borderRadius?: number
  borderStyle?: string
  borderWidth?: number
  borderTopWidth?: number
  borderRightWidth?: number
  borderBottomWidth?: number
  borderLeftWidth?: number
}
const borderProps = (props: BorderProps) => {
  return {
    borderColor: props.borderColor,
    borderRadius: `${props.borderRadius ?? 0}px`,
    borderStyle: props.borderStyle,
    borderWidth: `${props.borderWidth ?? 0}px`,
    ...(props.borderTopWidth !== undefined && {
      borderTopWidth: `${props.borderTopWidth}px`,
    }),
    ...(props.borderRightWidth !== undefined && {
      borderRightWidth: `${props.borderRightWidth}px`,
    }),
    ...(props.borderBottomWidth !== undefined && {
      borderBottomWidth: `${props.borderBottomWidth}px`,
    }),
    ...(props.borderLeftWidth !== undefined && {
      borderLeftWidth: `${props.borderLeftWidth}px`,
    }),
  }
}

interface LayoutProps {
  flexDirection?: any
  flexWrap?: any
  justifyContent?: any
  alignContent?: any
}

const layoutProps = (props: LayoutProps) => ({
  flexDirection: props.flexDirection,
  flexWrap: props.flexWrap,
  justifyContent: props.justifyContent,
  alignContent: props.alignContent,
})
export interface ContainerProps
  extends LayoutProps,
    DimensionProps,
    SpacingProps,
    BorderProps {
  children?: React.ReactNode
  backgroundColor?: string
  style?: React.CSSProperties // Include style for additional styles
}

export const getContainerStyles = (
  props: ContainerProps,
): React.CSSProperties =>
  removeUndefinedProperties({
    display: 'flex',
    backgroundColor: props.backgroundColor,
    ...dimensionProps(props),
    ...spacingProps(props),
    ...layoutProps(props),
    ...borderProps(props),
    ...props.style,
  })

export interface TextStyleProps
  extends DimensionProps,
    SpacingProps,
    BorderProps {
  text?: string
  backgroundColor?: string
  style?: React.CSSProperties
}

// function for generating Text styles
export const getTextStyles = (props: TextStyleProps): React.CSSProperties =>
  removeUndefinedProperties({
    display: 'flex',
    backgroundColor: props.backgroundColor,
    ...borderProps(props),
    ...dimensionProps(props),
    ...spacingProps(props),
    ...props.style,
  })

export interface LineProps extends DimensionProps, SpacingProps {
  backgroundColor?: string
  style?: React.CSSProperties
}

// Function to generate line styles
export const getLineStyles = (props: LineProps): React.CSSProperties =>
  removeUndefinedProperties({
    backgroundColor: props.backgroundColor,
    ...dimensionProps(props),
    ...spacingProps(props),
    ...props.style,
  })

export interface PageProps
  extends DimensionProps,
    SpacingProps,
    BorderProps,
    LayoutProps {
  children?: React.ReactNode
  backgroundColor?: string
  style?: React.CSSProperties
}

export const getPageStyles = (props: PageProps): React.CSSProperties =>
  removeUndefinedProperties({
    display: 'flex',
    backgroundColor: props.backgroundColor ?? 'white',
    ...borderProps(props),
    ...layoutProps(props),
    ...spacingProps(props),
    ...props.style,
  })

export interface FileProps extends DimensionProps, SpacingProps {
  documentId: string
  documentName: string
  documentDescription?: string
  documentMimetype: string
  documentThumbnailUrl?: string
  documentDownloadUrl?: string
  style?: React.CSSProperties
}

type ClipProps = {
  endTime?: string
  startTime?: string
}

export type VideoProps = FileProps & ClipProps

export const getVideoStyles = (props: VideoProps): React.CSSProperties =>
  removeUndefinedProperties({
    ...dimensionProps(props),
    ...spacingProps(props),
    ...props.style,
  })

export type AudioProps = FileProps & ClipProps

export const getAudioStyles = (props: AudioProps): React.CSSProperties =>
  removeUndefinedProperties({
    ...dimensionProps(props),
    ...spacingProps(props),
    ...props.style,
  })

export type ImageProps = FileProps

export const getImageStyles = (props: ImageProps): React.CSSProperties =>
  removeUndefinedProperties({
    ...dimensionProps(props),
    ...spacingProps(props),
    ...props.style,
  })

export const getFileStyles = (props: FileProps): React.CSSProperties =>
  removeUndefinedProperties({
    ...dimensionProps(props),
    ...spacingProps(props),
    ...props.style,
  })
