import {
  Image as ChakraImage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  Box,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { ErrorMessage } from '../ErrorMessage'
import { ImageProps } from '../ProjectReports/ProjectReportsStyles'

interface ImagePlayerProps extends ImageProps {
  imageUrl?: string
  fullScreenImageUrl?: string
  onClick?: (e: React.MouseEvent) => void
  enableFullScreen?: boolean
}

export const ImagePlayer: React.FC<ImagePlayerProps> = ({
  imageUrl,
  fullScreenImageUrl,
  documentName,
  width,
  height,
  onClick,
  enableFullScreen = true,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [error, setError] = useState<Error | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)

  const handleImageError = (e: any) => {
    console.error('Image error', e)
    setError(e)
  }

  useEffect(() => {
    if (imageUrl) {
      setIsLoading(false)
    }
  }, [imageUrl])

  const handleImageClick = (e: React.MouseEvent) => {
    if (onClick) {
      onClick(e) // Pass event to parent's handler
    }

    // If event propagation wasn't stopped, open the modal
    if (!e.defaultPrevented && enableFullScreen) {
      onOpen()
    }
  }

  if (error) {
    return <ErrorMessage>{error.message}</ErrorMessage>
  }

  return (
    <Box width={`${width}px`} height={`${height}px`}>
      {isLoading ? (
        <Box
          width="100%"
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner maxW="50px" h="50px" w="50px" />
        </Box>
      ) : (
        <>
          <ChakraImage
            src={imageUrl}
            alt={documentName}
            onClick={handleImageClick} // Use combined click handler
            width="100%"
            height="100%"
            objectFit="contain"
            onError={handleImageError}
          />

          {fullScreenImageUrl && ( // Only render modal if fullScreenImageUrl is provided
            <Modal isOpen={isOpen} onClose={onClose} size="xl">
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{documentName}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <ChakraImage
                    src={fullScreenImageUrl}
                    alt={documentName}
                    onError={handleImageError}
                  />
                </ModalBody>
                <ModalFooter />
              </ModalContent>
            </Modal>
          )}
        </>
      )}
    </Box>
  )
}
