import { SVGProps } from '../SVGProps'
import { BaseIcon } from '../_BaseIcon'

export const Reel = ({ ...rest }: SVGProps) => (
  <BaseIcon {...rest}>
    <title>add clip/reel</title>
    <svg width="24px" height="24px" viewBox="0 0 24 24">
      <g
        id="add-clip/reel"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M17,1 C18.1045695,1 19,1.8954305 19,3 L19,10 L17,10 L17,3 L3,3 L3,17 L10,17 L10,19 L3,19 C1.8954305,19 1,18.1045695 1,17 L1,3 C1,1.8954305 1.8954305,1 3,1 L17,1 Z M8,7 L12,10 L8,13 L8,7 Z M15,17 L13,17 L13,15 L15,15 L15,13 L17,13 L17,15 L19,15 L19,17 L17,17 L17,19 L15,19 L15,17 Z"
          id="icon"
          fill={rest.fill ?? '#000000'}
        />
      </g>
    </svg>
  </BaseIcon>
)
