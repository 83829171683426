import { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import { P } from './Typography'
import { Folder } from '@aurelius/icons'
import { Box, BoxProps } from '@chakra-ui/react'
import { Menu, MenuItem } from './Menu'
import { Details, Summary } from './Toggle'

const Wrapper = styled.div`
  border-radius: 0 0px 18.5px 18.5px;
  & section {
    border: none;
  }
`

export const ProjectFolder = ({
  name,
  children,
  menuItems = [],
  onCloseMenu,
  onOpen,
  indent = 0,
  isOpen = false,
  ...props
}: React.PropsWithChildren<
  {
    name: string
    onCloseMenu?: VoidFunction
    onOpen?: VoidFunction
    menuItems?: MenuItem[]
    indent?: number
    isOpen?: boolean
  } & BoxProps
>) => {
  const [open, toggleOpen] = useState(isOpen)
  useEffect(() => {
    if (!open && isOpen != open) {
      toggleOpen(isOpen)
    }
  }, [isOpen, open])

  return (
    <Details display="block" padding="30px 40px" open={open} {...props}>
      <Summary
        open={open}
        marginLeft={indent * 40 + 'px'}
        padding="4px 23px 4px 20px"
        onClick={(e: any): void => {
          e.preventDefault()
          toggleOpen(!open)
          if (!open) {
            onOpen?.()
          }
        }}
      >
        <Box width="100%" display="flex" alignItems="center">
          <Folder marginRight="16px" height={24} width={24} />
          <P
            fontSize="16px"
            fontWeight="bold"
            fontStyle="normal"
            lineHeight="normal"
            letterSpacing="0.29px"
            margin="0"
            color="var(--colors-typography-header)"
            display="inline-block"
          >
            {name}
          </P>
        </Box>
        <Menu placement="bottom-end" items={menuItems} onClose={onCloseMenu} />
      </Summary>
      <Wrapper>{children}</Wrapper>
    </Details>
  )
}
